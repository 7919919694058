import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PASSWORD_PATTERN } from 'app/app.constants';

export class CustomValidators {
  static phoneNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      /**
       * https://olubunmi-alegbeleye.medium.com/a-regular-expression-that-matches-nigerian-phone-numbers-d00fb32b6949
       */
      const phoneNumberPattern = /^(\+234|234|0)(7|8|9)(0|1)[0-9]{8}$/;
      if (!control.value) {
        return null; // Don't validate empty values to allow required validator to handle them
      }
      return phoneNumberPattern.test(control.value)
        ? null
        : { phoneNumber: true };
    };
  }

  static passwordStrength(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // const passwordPattern =
      //   /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,30}$/;
      if (!control.value) {
        return null; // Don't validate empty values to allow required validator to handle them
      }
      return PASSWORD_PATTERN.test(control.value)
        ? null
        : { passwordStrength: true };
    };
  }

  static bvn(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const bvnPattern = /^\d{11}$/;
      if (!control.value) {
        return null; // Don't validate empty values to allow required validator to handle them
      }
      return bvnPattern.test(control.value) ? null : { bvn: true };
    };
  }

  static passwordsMatch(
    controlName: string,
    matchingControlName: string
  ): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const control = group.get(controlName);
      const matchingControl = group.get(matchingControlName);

      if (!control || !matchingControl) {
        return { controlNotFound: false };
      }

      const error =
        control.value === matchingControl.value
          ? null
          : { passwordMismatch: true };

      matchingControl.setErrors(error);

      return error;
    };
  }
}
